@import 'variables';

* { 
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

body {
	margin: 0;
	font-family: $base-font;
	font-weight: 300;
	color: $gray-text;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Sanchez', serif;
  margin: 0 0 2rem 0;
}

h2 {
	font-size: 3.2rem
}

h3 {
	font-size: 2.8rem;
	text-align: center;
}

h4 {
	font-size: 2.4rem;
}

p {
	font-size: 1.6rem;
	line-height: 1.9;
	margin: 0 0 2rem 0;
	color: $body-copy;
}

a {
	text-decoration: none;
	transition: color 350ms ease-in-out;
}

.container {
	max-width: 1140px;
	margin: 0 auto;
	width: 100%;
	padding: 0 2rem;
}

.img-responsive {
	width: 100%;
	max-width: 100%;
}

.btn {
	background: $orange;
	display: inline-block;
	color: $white;
	width: 100%;
	text-align: center;
	border: 0;
	border-radius: 1rem;
	padding: 1.2rem 1.2rem;
	font-size: 1.6rem;
	letter-spacing: 0.08rem;
	transition: background 350ms ease-in-out;
	&:hover{
		background: darken($orange, 15%);
	}
}

footer {
	margin-top: 8rem;
	background: url('../images/footer.jpg') center center no-repeat;
	background-size: cover;
	display: flex;
	padding: 8rem 0;
	text-align: center;
	color: $white;
	text-shadow: 0px 3px 6px rgba(0, 0, 0, .75);
	a{
		color: $white;
		&:hover {
			color: $orange;
		}
	}

	p {
		color: $white;
		font-size: 1.4rem;
	}
}
