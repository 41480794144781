@import 'variables';

.location-header {
  margin: 4rem 0;
  text-align: center;
}

.accommodations {
  max-width: 800px;
  margin: 0 auto 4rem;
  padding: 0 2rem;
  width: 100%;

  h2 a {
    color: $orange;
  }

  .tel {
    color: $orange;
  }

	address {
		margin: 0 0 2rem 0;
		font-size: 1.4rem;
	}
}

