@import 'variables';

.page-header {
	margin: 5.5rem 0 8rem 0;
	background: url('../images/banner.jpg') center center no-repeat;
	background-size: cover;
	padding: 4rem 0;
	color: $white;
	text-align: center;
	text-shadow: 0px 3px 5px rgba(0, 0, 0, .4);

	h2 {
		font-size: 3rem;
	}

	h3 {
		font-size: 2.4rem; 
		margin: 0;
	}
}