@import 'variables';

.nav {
	background: $white;
	display: flex;
	box-shadow: 0 2px 5px rgba(0,0,0,.25);
	overflow: hidden;
	position: fixed;
	z-index: 5;
	width: 100%;
	top: 0;
	justify-content: center;
	height: 6rem;
	ul {
		list-style: none;
		padding: 0 1rem 0 0;
		margin: 2rem 0;
		li {
			margin: 0 1rem;
			display: inline;
			font-size: 1.4rem;
			a {
				color: $gray;
				position: relative;
				&:hover {
					color: $orange;
					&:after{
    				transform: translateY(-3rem);
					}
				}
				&:after {
					content: '';
					position: absolute;
					width: 100%;
					left: 0;
					bottom: -4rem;
					overflow: hidden;
			    border-bottom: 2px solid $orange;
    			transform: translateY(1rem);
					transition: transform 250ms ease-in;
				}
			}
		}
	}
}