@import 'variables';

.our-story {
  max-width: 800px;
  margin: 8rem auto 0;
  padding: 0 2rem;
  width: 100%;
  position: relative;

	h2 {
		text-align: center;
		margin: 0 0 2rem 0;
		padding: 0 0 2rem 0;
		border-bottom: 1px solid rgba(0,0,0,0.3);
	}
}


.save-the-date {
	background: url('../images/welcome-photo-hd.jpg') 65% 0 no-repeat;
	background-size: cover;
	height: calc(75vh - 25px);
	display: flex;
	align-items: flex-end;
	color: $white;
	text-align: center;
	overflow: hidden;
	text-shadow: 0px 3px 6px rgba(0, 0, 0, .75);

	@media (min-width: 768px) {
		background-position: center center;
	}

	p {
		color: $white;
		font-size: 2rem;
		margin: 0;
		line-height: 1;
	}

	h2 {
		font-size: 6rem;
		margin: 0;
		text-transform: uppercase;
		span {
			text-transform: lowercase;
		}
	}

	h3 {
		font-size: 3rem;
		margin: 0 0 1rem 0;
	}
}

.overlay {
	padding: 2rem 0;
	width: 100%;
  background: rgba(0,0,0, .5);
  background: linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.2));
}


@keyframes fadeIn {
	100% {
		opacity: 1;
	}
}

.fadeIn {
  padding: 0 2rem;
	opacity: 0;
	animation: fadeIn 450ms ease-in-out 1500ms 1 forwards;
}